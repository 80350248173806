import { Icons } from '@hypersay/ui';
import * as React from 'react';
import T from '../components/T';

export interface FeatureItemProps {
  name: React.ReactElement;
  description: React.ReactElement;
  icon: React.ReactElement;
}

export interface FeatureCategoryProps {
  name: React.ReactElement;
  items: FeatureItemProps[];
}

export const data: FeatureCategoryProps[] = [
  {
    name: <T id="Before session" />,
    items: [
      {
        name: <T id="Upload Presentations" />,
        description: (
          <T id="Start by uploading your slides (Powerpoint, PDF or Google Slides)." />
        ),
        icon: <Icons.CloudUpload theme="dark" size="l" />,
      },
      {
        name: <T id="Polls / Quizzes" />,
        description: (
          <T id="Use templates to define live polls & quizzes and add them to your slides." />
        ),
        icon: <Icons.Quiz theme="dark" size="l" />,
      },
      {
        name: <T id="Opinion Scales" />,
        description: (
          <T id="Use 5-step Likert scales to assess your audience opinions." />
        ),
        icon: <Icons.Finger theme="dark" size="l" />,
      },
      {
        name: <T id="Live Open Questions" />,
        description: <T id="Add open questions with limited length answers." />,
        icon: <Icons.OpenQuestionFilled theme="dark" size="l" />,
      },
      {
        name: <T id="Interactive Images" />,
        description: (
          <T id="Upload images & allow participants to drag dots." />
        ),
        icon: <Icons.Image theme="dark" size="l" />,
      },
      {
        name: <T id="Videos" />,
        description: (
          <T id="Embed YouTube / Vimeo videos in your presentation." />
        ),
        icon: <Icons.Video theme="dark" size="l" />,
      },
      {
        name: <T id="Secure OTP Authentication" />,
        description: (
          <T id="Sign in using social media or a secure One Time Password." />
        ),
        icon: <Icons.Lock theme="dark" size="l" />,
      },
      {
        name: <T id="Multiple Language Interface" />,
        description: (
          <T id="Switch from English to Italian, Romanian, French, Spanish or Portuguese." />
        ),
        icon: <Icons.World theme="dark" size="l" />,
      },
    ],
  },
  {
    name: <T id="Live presentation" />,
    items: [
      {
        name: <T id="Welcome Screen" />,
        description: (
          <T id="Welcome your participants with a gamified experiece even before the session starts." />
        ),
        icon: <Icons.WelcomeFilled theme="dark" size="l" />,
      },
      {
        name: <T id="QR Code Access" />,
        description: (
          <T id="Your participants can access the presentation using a QR code." />
        ),
        icon: <Icons.QR theme="dark" size="l" />,
      },
      {
        name: <T id="Live Slide Sharing" />,
        description: (
          <T id="Share slides with participants in real-time on their devices." />
        ),
        icon: <Icons.LiveSharing theme="dark" size="l" />,
      },
      {
        name: <T id="Freeze Answers" />,
        description: <T id="You can freeze answers on a specific quiz." />,
        icon: <Icons.Lock theme="dark" size="l" />,
      },
      {
        name: <T id="Audience Q&A Wall" />,
        description: (
          <T id="Crowdsource questions from participants displayed in order of votes received" />
        ),
        icon: <Icons.AskQuestionsFilled theme="dark" size="l" />,
      },
      {
        name: <T id="Question Wall Moderation" />,
        description: (
          <T id="Presenters can moderate the questions asked by participants." />
        ),
        icon: <Icons.AskQuestionsFilled theme="dark" size="l" />,
      },
      {
        name: <T id="Claps" />,
        description: (
          <T id="Participants can send claps during the presentation." />
        ),
        icon: <Icons.ClapsFilled theme="dark" size="l" />,
      },
      {
        name: <T id="Clicker" />,
        description: (
          <T id="Use a clicker to advance slides. Stay handsfree during your presentation." />
        ),
        icon: <Icons.FingerExtended theme="dark" size="l" />,
      },
      {
        name: <T id="Private Notes" />,
        description: (
          <T id="Participants can take private notes which stay linked to specific slides." />
        ),
        icon: <Icons.NotesFilled theme="dark" size="l" />,
      },
      {
        name: <T id="Feedback" />,
        description: (
          <T id="Gather instantaneous feedback at the end of your session." />
        ),
        icon: <Icons.FeedbackFilled theme="dark" size="l" />,
      },
      {
        name: <T id="Phonetic Codes" />,
        description: (
          <T id="Share your session using easy-to-pronounce codes." />
        ),
        icon: <Icons.T theme="dark" size="l" />,
      },
      {
        name: <T id="Anonymity" />,
        description: <T id="Allow unregistered participants to join." />,
        icon: <Icons.AnonymousFilled theme="dark" size="l" />,
      },
    ],
  },
  {
    name: <T id="Post Session" />,
    items: [
      {
        name: <T id="Download data as CSV" />,
        description: (
          <T id="Export data from your presentation into CSV format." />
        ),
        icon: <Icons.ArrowCircle theme="dark" size="l" />,
      },
      {
        name: <T id="Digital Pulse" />,
        description: (
          <T id="Analyse how engaged your participants were during your session. Actionable session analytics." />
        ),
        icon: <Icons.Analytics theme="dark" size="l" />,
      },
      {
        name: <T id="Follow up" />,
        description: (
          <T id="Quickly email your registered participants after your session." />
        ),
        icon: <Icons.FollowupFilled theme="dark" size="l" />,
      },
    ],
  },
];

export default data;
