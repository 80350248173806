import { ContainerMaxWidth, Stack } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import React from 'react';

import SEO from '../components/seo';
import T from '../components/T';
import Layout from '../layouts/default';

import {
  data,
  FeatureCategoryProps,
  FeatureItemProps,
} from '../data/featuresData';
import * as styles from './live-presentations.module.scss';

const renderItem = ({ name, description, icon }: FeatureItemProps) => (
  <div className={styles.item} key={JSON.stringify(name)}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.text}>
      <div className={styles.header}>{name}</div>
      <div className={styles.desc}>{description}</div>
    </div>
  </div>
);

const featuresRender = (localData: FeatureCategoryProps[]) => {
  return (
    <div className={`${styles.container} ${styles.containerAllFeatures}`}>
      <T tagName="h1" id="All features" />
      {localData.map(({ name, items }: FeatureCategoryProps) => (
        <div key={JSON.stringify(name)} className={styles.category}>
          <h2 style={{ lineHeight: '300%' }}>{name}</h2>
          <div className={styles.featuresGrid}>{items.map(renderItem)}</div>
        </div>
      ))}
    </div>
  );
};

const BlobInteractions = () => (
  <svg width="100%" height="100%" viewBox="0 0 456 534">
    <path
      // tslint:disable-next-line: max-line-length
      d="M250.134 187.271c47.128 37.138 125.814 28.026 167.155 54.81 41.34 26.784 45.199 89.463 31.832 151.314-13.505 61.99-44.235 123.012-93.43 137.232-49.058 14.083-116.581-18.638-162.745-48.183-46.164-29.407-71.106-55.362-92.88-78.418-21.91-23.056-40.65-43.075-47.679-66.545-7.028-23.47-2.205-50.254-16.95-107.825C20.693 172.084-13.62 83.864 5.81 37.752 25.24-8.498 98.413-12.502 142.786 27.12c44.51 39.761 60.082 123.012 107.348 160.15z"
      fill="#097CA0"
      fillRule="nonzero"
      fillOpacity={0.3}
    />
  </svg>
);

const BlobGolive = () => (
  <svg width="100%" height="100%" viewBox="0 0 566 475">
    <path
      // tslint:disable-next-line: max-line-length
      d="M451.9 189.425c36.646 52.757 99.87 104.555 111.814 162.813 11.945 58.259-27.519 123.196-86.372 121.987-58.852-1.209-137.16-68.456-193.581-87.805-56.422-19.35-90.893 9.09-125.922 10.761-34.854 1.629-70.09-23.553-99.47-60.956C29.099 298.888 5.64 249.156.925 190.49c-4.826-58.733 8.98-126.465 50.638-159.94C93.22-2.928 162.665-2.037 224.617 3.41c61.952 5.447 116.412 15.45 148.61 47.42 32.2 31.968 42.028 85.837 78.674 138.594z"
      fill="#FE840E"
      fillRule="nonzero"
      opacity={0.3}
    />
  </svg>
);

const BlobAnalytics = () => (
  <svg width="100%" height="100%" viewBox="0 0 566 629">
    <path
      // tslint:disable-next-line: max-line-length
      d="M18.067 414.654c-24.04-65.593-25.634-144.515 9.466-199.632 35.21-55.006 107.115-85.985 178.345-130.526 71.008-44.54 141.343-102.642 186.706-78.834 45.363 23.809 65.865 129.195 63.342 202.446-2.522 73.473-27.958 114.478-.035 161.954 27.922 47.477 109.203 101.426 109.212 121.212.01 19.563-81.364 5.075-139.154 29.058-57.79 23.983-91.886 86.326-136.674 103.534-44.677 17.097-99.713-11.051-150.419-51.09-50.706-40.263-96.971-92.528-120.79-158.122z"
      fill="#962752"
      fillRule="nonzero"
      fillOpacity={0.3}
    />
  </svg>
);

const Features = () => (
  <Layout>
    <SEO
      title="Features - Hypersay"
      description="Hypersay offers over 25 features to help you prepare, deliver and analyse your presentations. Hypersay is a professional platform for interactive presentations used by more than 180k users around the world."
      keywords={[
        'live presentation',
        'interactive presentations',
        'live polling',
        'live quiz',
        'likert scale',
        'opinion scale',
        'open questions',
        'word cloud',
        'google slides',
        'powerpoint',
        'analytics',
        'presentation analytics',
        'follow up',
        'question wall',
        'digital pulse',
        'download data as csv',
        'export data',
        'embed video',
        'feedback form',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <div className={styles.highlightContainer}>
        <Stack className={`${styles.highlightColumn}`}>
          <img src={withPrefix('/images/LiveSlides.svg')} alt="interactions" />
          <div className={styles.betaTitle}>
            <T tagName="h2" id="Live Presentations" />
          </div>
          <T
            tagName="p"
            // tslint:disable-next-line: max-line-length
            id="Slides are great but not enough. Use Hypersay to engage your audience by sharing your slides through a QR code, add interactions such as live polls and gather feedback at the end. All in one screen."
          />
        </Stack>
      </div>

      <div className={styles.highlightContainer}>
        <div className={`${styles.highlight} ${styles.interactions}`}>
          <div className={styles.image}>
            <img
              src={withPrefix('/images/feature-interactions.png')}
              alt="interactions"
            />
            <div className={styles.svgContainer}>
              <BlobInteractions />
            </div>
          </div>
          <div className={styles.text}>
            <T tagName="h2" id="Add interactions to your slides" />
            <T
              tagName="p"
              // tslint:disable-next-line: max-line-length
              id="Once you’ve uploaded your slides, you are ready to start adding interactions. We provide templates from simple yes/no polls to complex opinion (Likert) scales to suit all your enquiring needs. Diversify by using open-ended questions, interactive images and other quirky ways to capture your audience’s attention."
            />
          </div>
        </div>
        <div className={`${styles.highlight} ${styles.goLive}`}>
          <div className={styles.text}>
            <T tagName="h2" id="Show time! Start your live presentation!" />
            <T
              tagName="p"
              // tslint:disable-next-line: max-line-length
              id="Now you are ready to start your presentation. Use the “welcome screen” to share the secret code with your audience, see how they connect to your session and, when ready, hit the “start session button”. Show time!"
            />
            <T
              tagName="p"
              // tslint:disable-next-line: max-line-length
              id="We offer you fine controls such as freezing the questions and revealing the answers only when needed. Gather questions on the question wall and keep an eye on their participation levels. All from one screen."
            />
          </div>
          <div className={styles.image}>
            <img
              src={withPrefix('/images/feature-golive2.png')}
              alt="interactions"
            />
            <div className={styles.svgContainer}>
              <BlobGolive />
            </div>
          </div>
        </div>
        <div className={`${styles.highlight} ${styles.analytics}`}>
          <div className={styles.image}>
            <img
              src={withPrefix('/images/feature-analytics.png')}
              alt="interactions"
            />
            <div className={styles.svgContainer}>
              <BlobAnalytics />
            </div>
          </div>
          <div className={styles.text}>
            <T tagName="h2" id="How did it go? See analytics" />
            <T
              tagName="p"
              // tslint:disable-next-line: max-line-length
              id="Great presentations don’t end when you leave the room! After you close the session you can immediately access session analytics. Here you can take the “digital pulse” of your participants: see how engaged they were, who lagged behind and who performed above expectations and even learn about your own time spent on specific slides. A wealth of data to improve your next sessions."
            />
          </div>
        </div>
      </div>

      {/* <StaticQuery query={featuresQuery} render={featuresRender} /> */}
      {featuresRender(data)}
    </ContainerMaxWidth>
  </Layout>
);

export default Features;
